<template>
  <div class="news-details-content-container">
      <div class="news-details-content-top">
        <p class="news-details-article-title">{{ currentArticleObject.title }}</p>
        <div class="news-details-article-info">
          <p class="news-details-article-create-time">{{ currentArticleObject.updateTime }}</p>
          <p class="news-details-article-author">作者：{{ currentArticleObject.author }}</p>
          <div class="news-details-article-page-view-box">
            <img class="news-details-article-page-view-icon" src="/images/home/page_views.png" alt="page_view">
            <p class="news-details-article-page-view-number">{{ currentArticleObject.pageView }}</p>
          </div>
        </div>
      </div>
      <div class="news-details-content-bottom" v-html="currentArticleObject.content"></div>
  </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
export default {
  props:{
    articleObject:{
      type:Object,
      default:()=>({})
    }
  },
  mounted(){
    if(Object.keys(this.articleObject).length>0){
      this.setCurrentNewsItemObject(this.articleObject);
    }
  },
  methods:{
    ...mapMutations('gameNews',['setCurrentNewsItemObject'])
  },
  computed:{
    ...mapState({
      currentArticleObject:state=>state.gameNews.currentNewsItemObject
    })
  }
}
</script>

<style lang="less" scoped>
.news-details-content-container{
    width: 100%;
    min-width: 860px;
    height: 100%;
    .news-details-content-top{
      width: 820px;
      display: flex;
      flex-direction: column;
      margin: 20px;
      height: 102px;
      box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
      .news-details-article-title{
        height: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 24px;
        color: #222222;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .news-details-article-info{
        margin-top: 20px;
        width: 820px;
        height: 20px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .news-details-article-create-time{
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #999999;
          font-style: normal;
        }
        .news-details-article-author{
          margin-left: 40px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #999999;
          font-style: normal;
        }
        .news-details-article-page-view-box{
          margin-left: 40px;
          display: flex;
          flex-wrap: nowrap;
          height: 20px;
          align-items: center;
          .news-details-article-page-view-icon{
            width: 11px;
            height: 8px;
          }
          .news-details-article-page-view-number{
            margin-left: 5px;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #999999;
            font-style: normal;
          }
        }
      }
    }
    .news-details-content-bottom{
      margin-top: 30px;
      width: 820px;
      margin: 20px;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #222222;
      line-height: 38px;
      text-align: left;
      text-align: justify;
      font-style: normal;
    }

}
</style>